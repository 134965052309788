@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
 .course {
     font-family: 'Oswald', sans-serif;
     font-weight: 500;
     color: #1e252b;
     height: auto;
}
.course h1, h2, h3, h4, h5{
     margin: 0;
     line-height: 1;
}
.course ul, li{
     list-style: none;
     margin: 0;
     padding: 0;
}
 /* .bn_g_page-bg{
     background: url(../images/page-bg.png) no-repeat top;
} */
.course .bn_g_top-banner{
     text-align: center;
     color: #fff;
     text-shadow: 2px 2px 5px rgba(0,0,0,0.73);
     height: 280px;
}
.course .bn_g_top-banner h1{
     font-size:3rem;
     font-weight: 400;
     padding:0 0 15px 0;
}
.course .bn_g_top-banner h4{
     font-size:1.5rem;
     font-weight: 400;
     padding:0 0 15px 0;
}
.course .bn_g_custom-container{
     width:100%;
     max-width: 1200px;
     margin:0 auto;
}
.course .bn_g input[type="text"]{
     background: #f4f4f4;
     border:1px solid #000;
     border-radius: 5px;
     padding:10px;
     font-size: 16px;
}
.course .bn_g input[type="text"]:focus{
     outline: 0;
}
.course .bn_g .dropdown-toggle::after {
     border: solid black;
     border-width: 0 2px 2px 0;
     display: inline-block;
     padding: 5px;
     transform: rotate(45deg);
     -webkit-transform: rotate(45deg);
     float: right;
     margin-top: 3px;
}
.course .bn_g .dropdown-toggle{
     background: #f4f4f4;
     border:1px solid #000;
     border-radius: 5px;
     padding:10px;
     font-size: 16px;
     text-align: left;
     width: -webkit-calc(100% - 1px);
     width: -moz-calc(100% - 1px);
     width: calc(100% - 1px);
}
.course .bn_g .search-label{
     width:49%;
     display: inline-block;
     box-sizing: border-box;
}
.course .bn_g .search-input{
     width:49%;
     display: inline-block;
     box-sizing: border-box;
}
.course .bn_g .location-select{
     margin-top:15px;
}
.course .bn_g .loc-name{
     padding-top: 11px;
}
.course .bn_g .dropdown-menu{
     top: -7px!important;
     left: -1px!important;
     width: 100%;
     max-width: 277px;
     background: #f4f4f4!important;
     border-top: 0;
     border-left: 1px solid #000;
     border-right: 1px solid #000;
     border-bottom: 1px solid #000;
     border-top-left-radius:0;
     border-top-right-radius:0;
     margin-left: 1px!important;
     margin-right: -1px!important;
}
.course .bn_g .dropdown-menu li{
     padding:0px;
     width: 100% 
}
.course .bn_g .dropdown-menu li a{
     display: block;
     color: #000;
     width: 100%;
     padding:5px 10px;
}
.course .bn_g  .dropdown-menu li a:hover{
     text-decoration: none;
     background: #edebeb;
}
.course .bn_g .btn-primary{
     background: #fe5000;
     border:0;
     border-radius: 5px;
     padding: 10px 20px;
     font-size: 16px;
     color: #000;
     min-width: 125px;
     font-weight: 500;
}
.course .bn_g .btn-primary:hover, .btn-primary:focus, .btn-primary:active{
     background-color: #be3d01!important;
     color: #000!important;
     /* border:0!important; */
     box-shadow:none!important;
}
.course .bn_g .search-btn{
     margin-top:15px;
}
.course .bn_g .search-result{
     margin-top:0px;
}
.course .bn_g .search-result table{
     width: 100%;
     border-collapse: separate;
}
.course .bn_g .search-result td{
     background:#eeeeee;
     border-top:8px #fff solid;
     padding:20px;
     text-align: center;
     box-sizing: border-box;
}
.course .bn_g .search-result{
     overflow: auto;
     /* height: 425px; */
     max-height: 425px;
     border: 1px solid #000;
}
.course .bn_g .search-result-head{
     background-color: #fe5000;
     border: 1px solid #000;
     margin-top:30px;
     border-bottom: 0;
}
.course .bn_g .search-result-head .table{
     width: -webkit-calc(100% - 10px);
     width: -moz-calc(100% - 10px);
     width: calc(100% - 10px);
}
 @-moz-document url-prefix() {
     .course .bn_g  .search-result-head .table{
         width: -webkit-calc(100% - 17px);
         width: -moz-calc(100% - 17px);
         width: calc(100% - 17px);
    }
}
.course .bn_g textarea{
     background: #f4f4f4;
     border: 1px solid #000;
     border-radius: 5px;
     padding: 10px;
     font-size: 16px;
     min-height: 150px;
}
.course .req-space{
     margin-top:20px 
}
.course .search-result-head .table th{
     border:0;
     padding: 20px;
     text-align: center;
     box-sizing: border-box;
}
.course .search-result-head table{
     margin-bottom: 0;
}
.course .search-result .table tr:first-child td{
     border-top:0px;
}

.course .search-result::-webkit-scrollbar {
     width: 10px;
}

.course .search-result::-webkit-scrollbar-track {
     border-radius:0px;
}

.course .search-result::-webkit-scrollbar-thumb {
     background: #b6b6b6;
     border-radius: 10px;
}

.course .search-result::-webkit-scrollbar-thumb:hover {
     background: #b30000;
}
.course .request-course-btn{
     text-align: center;
     padding:30px 0;
}
.course .request-course-btn .btn-primary{
     margin-left:15px;
}
.course .req-course .dropdown-menu{
     max-width: 100%;
     width: -webkit-calc(100% - 1px);
     width: -moz-calc(100% - 1px);
     width: calc(100% - 1px);
}


/*============= developer added ===============*/

.course .loaderOverlay
{
     text-align: center;
     position: absolute;
     width: 100%;
     z-index: 999;
     height: 100vh !important;
     top: 0;
     bottom: 0;
     right: 0;
     left: 0;
     background-color: rgba(0,0,0,0.5);
     min-height: 120vh;
}

.course .loader
{
     min-height: 250px !important;
     margin-top: 220px;
}


.course .errorMsg
{
     color: red;
     font-size: 13px;
     font-weight: normal;
     padding-top: 5px;
     display: inline-block;
}



.course ::placeholder 
{
     color: #888888	!important;
     opacity: 1 !important;
}

.searchBtn:active
{
     background: #FE5000 !important;
     border: 1px solid#FE5000 !important;
}

.searchBtn:focus
{
     background: #FE5000 !important;
     border: 1px solid #FE5000 !important;
}

.searchBtn:hover
{
     border: 1px solid #be3d01 !important;
}

.course td
{
     text-align: left !important;
}

.course th
{
     text-align: left !important;
}

.emptyData
{
     text-align: center !important;
}
.pageNotFound
{
     padding: 40px !important;
}

.header404
{
     font-weight: 500;
     font-size: 30px;
     margin-bottom: 37px !important;
}

.content404
{
     font-weight: normal;
     margin-top: 10px;
}

.back-btn{
     padding-bottom: 15px;
     width: 100%;
     float: left;
 }
 .bn_g .btn-back {
     background: #d2d2d2;
     border: 0;
     border-radius: 5px;
     padding: 10px 20px;
     font-size: 16px;
     color: #000;
     min-width: 125px;
     font-weight: 500;
     text-align: center;
     text-decoration: none;
     border: 1px solid #d2d2d2;
     margin-right: 20px;
 }
 .bn_g .btn-back:hover 
 {
     background: #c9c9c9;
 }

 .course .custom-select2 select {
     -webkit-appearance: menulist-button;
     height: 46px !important;
     width: 100%;
     border-radius: 10px;
 }
 .marginAuto
 {
     margin: 0 auto;
 }

 .requestText{
      color: #fe5000 !important;
 }

 .hideControl {
     display: none;
 }

.captcha {
    padding-bottom: 5px;
}

.captcha > div {
    display: inline-flex;
 }

 .captcha > div > canvas {
    border-width: 2px;
    border-style: solid;
    border-color: #fe5000;
 }

 .captcha > div > div > a > img {
    width: 30px;
 }

 .mandatoryStar {
     color: red;
 }

/*============= developer added ===============*/


/**** Media Query ****/
 @media screen and (max-width: 500px) {
     .course .req-course .bn_g_top-banner h1 {
         font-size: 2.4rem;
         font-weight: 400;
         padding: 0 0 0px 0;
    }
    .course .req-course .bn_g_top-banner{
         height: 150px;
    }
    .course .bn_g_top-banner{
         height: 260px;
    }
    .course .bn_g_top-banner h1{
         font-size: 2.4rem;
         font-weight: 400;
         padding: 0 0 15px 0;
    }
    .course .bn_g_top-banner h4{
         font-size: 1.3rem;
         font-weight: 400;
         padding: 0 0 15px 0;
         line-height: 25px;
    }
    .course .loc-name{
         text-align: left!important;
    }
    .course .location-select .col-sm{
         margin-top: 15px!important;
    }
    .course .dropdown-menu{
         max-width: 100%;
         width: -webkit-calc(100% - 1px);
         width: -moz-calc(100% - 1px);
         width: calc(100% - 1px);
    }
    .course .location-select{
         margin-top:0px;
    }
    .course .search-result-head .table th{
         padding:10px 5px;
         font-size: 13px;
    }
    .course .search-result td{
         padding:10px 5px;
         font-size: 13px;
         font-weight: 400;
    }
    .course .search-result-head{
         margin-top:15px;
    }
    .course .request-course-btn {
         padding: 20px 0;
    }
    .course .request-course-btn .btn-primary{
         margin-left:0px;
         margin-top:10px;
    }
}
.course .mtb50
{
  margin-top: 50px;
  margin-bottom: 50px;   
}
 